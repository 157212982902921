import React from 'react';

const IconAndroid = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    viewBox="30 30 165 165">
    <title>Android</title>
    <path
      d="
        M46.716003,66.770470
        C67.361000,39.547909 94.531250,27.512699 127.725403,33.487877
        C161.481903,39.564281 183.440872,60.449699 192.384552,93.720787
        C204.935196,140.410080 171.682083,188.792801 123.908066,194.167908
        C79.790779,199.131577 41.230438,171.097534 33.287914,127.806602
        C29.266123,105.885719 34.094280,85.567711 46.716003,66.770470
        M141.889160,132.487823
        C141.889160,119.086380 141.889160,105.684937 141.889160,92.434448
        C122.550056,92.434448 103.982727,92.434448 85.383614,92.434448
        C85.383614,110.165604 85.383614,127.571205 85.383614,145.013626
        C87.750351,145.167206 89.843437,145.303040 92.145142,145.452408
        C92.145142,150.783600 92.122917,155.766037 92.151001,160.748199
        C92.183014,166.427979 94.705940,169.766953 99.045486,169.951935
        C103.737434,170.151932 106.538658,166.872452 106.624977,160.896851
        C106.698715,155.792267 106.640228,150.685806 106.640228,145.440292
        C111.445206,145.440292 115.694710,145.440292 120.342613,145.440292
        C120.342613,150.640427 120.300003,155.604050 120.353470,160.566635
        C120.419540,166.699585 122.956200,169.937119 127.596626,169.953644
        C132.115768,169.969742 134.822861,166.498322 134.850006,160.590851
        C134.873352,155.508728 134.854858,150.426422 134.854858,145.348495
        C137.529663,145.218887 139.483414,145.124222 141.889160,145.007645
        C141.889160,141.062317 141.889160,137.271729 141.889160,132.487823
        M132.112930,62.725506
        C132.454956,62.154350 133.049606,61.599113 133.086823,61.008751
        C133.166031,59.751659 132.955750,58.476330 132.864120,57.208469
        C131.879684,57.885799 130.694611,58.395771 129.957581,59.279446
        C128.834076,60.626495 128.020538,62.232090 127.000786,63.845970
        C117.784538,59.234909 108.817802,59.575329 99.785027,63.793503
        C98.741844,62.004295 98.008842,60.292114 96.855011,58.938709
        C96.162415,58.126316 94.812981,57.873894 93.758759,57.369804
        C93.779083,58.555714 93.499969,59.852394 93.888321,60.902153
        C94.505791,62.571289 95.568497,64.075714 96.596245,65.925911
        C89.640396,71.943153 85.166710,79.117195 85.284851,88.537216
        C104.333168,88.537216 123.031654,88.537216 141.741440,88.537216
        C141.372894,79.844658 139.051682,74.510696 132.810501,68.284813
        C131.291489,66.769539 129.718857,65.563202 132.112930,62.725506
        M145.094498,123.886581
        C145.645752,128.527985 148.050171,131.221878 152.929077,130.996811
        C157.060410,130.806229 159.427261,127.815285 159.479935,122.604652
        C159.567322,113.958427 159.617935,105.308136 159.444427,96.664368
        C159.343414,91.633026 156.541565,88.780968 152.330017,88.772018
        C147.947754,88.762711 145.145584,91.881096 145.108597,97.051102
        C145.046722,105.697861 145.091766,114.345383 145.094498,123.886581
        M81.908600,101.633652
        C81.908386,100.305084 81.927284,98.976181 81.904625,97.647995
        C81.808441,92.010567 79.232529,88.824615 74.735855,88.761627
        C70.272621,88.699120 67.560555,91.899033 67.512619,97.558037
        C67.442291,105.861053 67.423531,114.165939 67.520660,122.468422
        C67.588066,128.230377 70.227211,131.155090 74.904686,131.018127
        C79.271118,130.890259 81.843445,127.843758 81.892990,122.529160
        C81.954910,115.886841 81.908333,109.243523 81.908600,101.633652
    z"
    />
    <path
      d="
        M106.178856,74.986870
        C104.647301,76.224205 103.201126,77.282562 101.590210,77.690590
        C101.249924,77.776787 99.699188,75.420830 99.812035,74.301613
        C99.924713,73.184074 101.424904,72.206444 102.316818,71.167473
        C103.613052,72.303703 104.909286,73.439934 106.178856,74.986870
    z"
    />
    <path
      d="
        M123.233238,77.950218
        C122.276588,76.212502 121.391449,74.556190 121.376938,72.892273
        C121.372299,72.360703 123.851143,71.166313 124.873817,71.445763
        C125.914085,71.730003 127.475235,73.918274 127.200851,74.462807
        C126.482979,75.887444 124.887642,76.869934 123.233238,77.950218
    z"
    />
  </svg>
);

export default IconAndroid;
