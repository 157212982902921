import React from 'react';

const IconIOS = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    viewBox="0 0 1000 1000">
    <title>IOS</title>
    <path d="M500,10C229.4,10,10,229.4,10,500s219.4,490,490,490s490-219.4,490-490S770.6,10,500,10z M520.8,248.4c30.5-35.7,82.8-40.5,82.8-40.5s2.7,52.7-27.8,88.4S493,336.9,493,336.9S490.3,284,520.8,248.4z M717.2,616.4c0,0-20.7,52.9-43.2,87.1c-22.5,34-53.5,58.7-83.6,58.7s-57.3-23.7-86.4-23.7c-29.1,0-65.7,23.7-96.7,23.7c-31,0-63-27.6-99.3-95.8c-45.2-85.1-46.3-176.8-24.6-240.1c21.7-63.4,83.3-84.8,120.2-84.2c44.5,0.7,78,24.6,93.8,24.6c15,0,66.7-26.5,104.2-26.5c31.2,0,76.1,20.5,101.7,57.3c-36.5,20.3-61.4,59.5-61.4,104.5c0,50.6,31.4,93.7,75.6,111.1C717.3,614.8,717.2,616.4,717.2,616.4z"></path>
  </svg>
);

export default IconIOS;
