import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <path
          d="m52.20673,30.34172c0,0 -19.43393,0 -19.43393,0c0,0 0,10.45771 0,10.45771c0,0 17.89328,0 17.89328,0c1.31655,0 2.30007,0.29568 2.94746,0.88704c0.6505,0.59135 0.97419,1.36947 0.97419,2.33743c0,0.97107 -0.32058,1.75851 -0.95863,2.36855c-0.63805,0.61004 -1.62779,0.91504 -2.96302,0.91504c0,0 -17.89328,0 -17.89328,0c0,0 0,12.11353 0,12.11353c0,0 20.09998,0 20.09998,0c1.35702,0 2.37789,0.31436 3.06573,0.94307c0.68784,0.63182 1.03022,1.46907 1.03022,2.51483c0,1.00842 -0.34236,1.82388 -1.03022,2.4557c-0.68784,0.62871 -1.70872,0.94307 -3.06573,0.94307c0,0 -23.44272,0 -23.44272,0c-1.87678,0 -3.22758,-0.41707 -4.05236,-1.24809c-0.82169,-0.83413 -1.23562,-2.17869 -1.23562,-4.03991c0,0 0,-32.01432 0,-32.01432c0,-1.23873 0.18675,-2.25028 0.55401,-3.03461c0.36726,-0.78433 0.94307,-1.35389 1.72739,-1.71494c0.78433,-0.35792 1.78652,-0.53534 3.0066,-0.53534c0,0 22.77667,0 22.77667,0c1.37569,0 2.39656,0.30502 3.06573,0.91504c0.66605,0.61004 0.99908,1.40681 0.99908,2.39656c0,1.00531 -0.33303,1.81454 -0.99908,2.42457c-0.66917,0.61004 -1.69005,0.91504 -3.06573,0.91504l-0.00002,0.00002z"
          fill="currentColor"
        />
        <polygon
          id="Shape"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 0 0 22 0 67 39 90 78 68 78 23"
        />
      </g>
    </g>
  </svg>
);

export default IconLogo;
